import React from 'react';
import 'twin.macro';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import ProductItem from '../components/product/ProductItem';
import { Container } from '../components/atoms';
import config from '../utils/config';

export const shopQuery = graphql`
  query shopPageQuery {
    allSanityProduct(
      filter: { productVisibility: { in: ["shop", "both"] } }
      sort: { fields: [order, _updatedAt], order: [ASC] }
    ) {
      nodes {
        _id
        title
        slug {
          current
        }
        productVisibility
        numberOfJets
        numberOfSeats
        price
        description
        image {
          asset {
            gatsbyImageData
          }
        }
        isFeatured
        _rawBody
        colors {
          title
          value
        }
        condition {
          _id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const ShopPage = ({ data }) => {
  const products = data.allSanityProduct.nodes;
  if (!products) return null;
  return (
    <Layout>
      <Seo title="Shop" description={config.description} />
      <div tw="px-6">
        <Container>
          <div tw="grid grid-cols-1 md:grid-cols-3 gap-8 2xl:grid-cols-3">
            {products &&
              products.map((item, i) => (
                <ProductItem data={item} key={item._id || i + 1} />
              ))}
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ShopPage;
