import React from 'react';
import tw, { styled } from 'twin.macro';

import { Link } from 'gatsby';
import CurrencyFormat from '../global/CurrencyFormat';

import { Heading, Button, Subtitle, Tag } from '../atoms';
import SanityImage from '../global/SanityImage';

const SanityImageWrapper = styled(SanityImage)`
  img {
    object-fit: ${(props) => (props.primary ? 'contain' : 'cover')} !important;
    height: ${(props) => (props.primary ? '200px' : '230px')} !important;
  }
`;
const TagWrapper = styled(Tag)`
  background: ${(props) => (props.background ? props.background : '#239F9D')};
`;

const ProductItem = ({ data }) => {
  if (!data) return null;
  const checkIsForSale =
    data.productVisibility === 'shop' || data.productVisibility === 'both';
  return (
    <div
      css={[
        tw`rounded overflow-hidden shadow-lg  flex flex-col`,
        checkIsForSale ? tw`bg-gray-extraLight` : tw`bg-white`,
      ]}
    >
      <div
        css={[
          tw`shadow-xl relative max-h-full w-full box-border overflow-hidden bg-no-repeat bg-center bg-cover transition-transform ease-linear inline-block`,
          checkIsForSale ? tw`h-[200px]` : tw`h-[230px]`,
        ]}
      >
        <div tw="w-full">
          <div tw="w-full text-white p-2 absolute bottom-0 right-0 z-40">
            {data.numberOfSeats && (
              <Tag tw="mr-3"> {`${data.numberOfSeats} Seats`} </Tag>
            )}
            {data.numberOfJets && (
              <Tag tw="mr-3">{`${data.numberOfJets} Jets`}</Tag>
            )}
            {data.price > 0 && (
              <Tag tw="mr-3">
                <CurrencyFormat amount={data.price} />
              </Tag>
            )}
          </div>
          {data.condition && data.condition.title && (
            <div tw="w-full p-2 absolute top-0 right-0 z-40 flex justify-end">
              <TagWrapper
                background={
                  data.colors && data.colors.value
                    ? data.colors.value
                    : '#239F9D'
                }
                tw="mr-3 text-white"
              >
                {data.condition.title}
              </TagWrapper>
            </div>
          )}
        </div>
        {data.image?.asset && data.image.asset.gatsbyImageData && (
          <SanityImageWrapper
            primary={
              data.productVisibility === 'shop' ||
              data.productVisibility === 'both'
            }
            fluid={data.image.asset.gatsbyImageData}
            tw="w-full"
            alt={data.title}
          />
        )}
      </div>

      <div tw="px-6 py-4">
        <Heading>{data.title}</Heading>
        {data.description && (
          <Subtitle>{data.description.slice(0, 190)}...</Subtitle>
        )}
      </div>
      <div tw="px-6 pb-6 pt-2 mt-auto">
        <Link to={`/product/${data.slug.current}`}>
          <Button tw="mt-2">View Product</Button>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
